.apply__header {
  text-align: center;
  margin-bottom: 20px;
}

.apply__body {
  box-shadow: 0.5px 0.5px 5px -1px lightgray;
  background: white;
  width: 20%;
  margin: 0 40%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.apply__steps {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.apply__step {
  width: 35px;
  height: 35px;
  font-size: 14px;
  border-radius: 100%;
  border: 1px dashed #777;
  color: #777;
  padding: 10px;
  text-align: center;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply__step--active {
  border: 2px dashed #046307;
}
.apply__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 25px;
}

.apply__form-group {
  margin-bottom: 15px;
}

.apply__label {
  margin-bottom: 5px;
  font-weight: 500;
  color: #777;
  font-size: 13px;
  margin-bottom: 10px;
}

.apply__input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.apply__buttons {
  display: flex;
  justify-content: space-between;
}
.apply__button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: white;
  cursor: pointer;
}
.apply__button.submit {
  background-color: #046307;
}

.apply__button:hover {
  opacity: 0.75;
}
.apply__button--back {
  background-color: #6c757d;
}

.apply__button--back:hover {
  background-color: #5a6268;
}

@media all and (max-width: 768px) {
  .apply__body {
    min-height: 60vh;
    background: white;
    width: 100%;
    margin: 0;
    overflow: hidden;
  }
}
