.kyc__body__section__one {
  padding: 0 0;
}
.kyc__body__section__one__select__div {
  width: 100%;
  border-bottom: 1px solid lightgray;
}
.kyc__body__section__one__select {
  border: 0;
  padding: 15px 0;
  background: transparent;
  margin-right: 25px;
  cursor: pointer;
  font-weight: 800;
  font-size: 13px;
  color: #999;
}
.kyc__body__section__one__select:last-of-type {
  margin-right: 0;
}
.kyc__body__section__one__select.selected {
  border-bottom: 2.5px solid #046307;
  color: #046307;
}

.kyc__body {
  min-height: 80vh;
  box-shadow: 0.5px 0.5px 5px -1px lightgray;
  width: 40%;
  margin: 0 30%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5% 2.5%;
}
.kyc__body__section__two {
  padding-top: 30px;
}
.kyc__body__section__two > h3 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin: 10px 0;
  color: #046307;
}
.kyc__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modal__apply__content__input__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.modal__apply__content__input__wrap > span {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 49%;
  margin-bottom: 10px;
}
.modal__apply__content__input__wrap > span > input {
  padding: 10px 15px;
  color: #222222;
  border: 1px solid #ccc;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px;
}
.modal__apply__content > h1 {
  font-size: 22.5px;
  font-weight: 500;
  margin-bottom: 0;
  color: #444;
  margin-bottom: 5px;
}
.modal__apply__content > p {
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: #666;
}
.modal__apply__buttons {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
}
.modal__apply__button {
  padding: 12.5px 25px;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: white;
  background: #cc66cc;
  cursor: pointer;
}
.modal__apply__close {
  padding: 12.5px 25px;
  border: 0;
  font-size: 13px;
  font-weight: 500;
  color: #222222;
  background: white;
  border: 1px solid #222222;
  cursor: pointer;
}
.modal__apply__label {
  font-size: 12px;
  font-weight: 700;
  color: #777;
  margin-bottom: 10px;
}
.modal__apply__input {
  padding: 10px 15px;
  color: #222222;
  border: 1px solid #ccc;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px;
  min-height: 30px;
}
.image__upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px dashed #1475cf;
  min-height: 30px;
  cursor: pointer;
  border-radius: 5px;
  gap: 10px;
  padding: 5px 5px;
  width: 100%;
}
.image__upload__label {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 11px;
  color: var(--tertiary-text-color);
  background: #e9f0ff;
  padding: 5px;
}
.image__upload__label > span {
  margin-left: auto;
}
.modal__apply__message {
  padding: 20px;
  color: #222222;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  background-color: lightgreen;
  width: 500px;
  text-align: center;
}
.modal__apply__message.incomplete {
  background-color: #ff4d4d77;
  color: crimson;
  padding: 10px 20px;
}
.kyc__form > button {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: white;
  background-color: #046307;
  padding: 10px 15px;
  border-radius: 5px;
  width: 30%;
  cursor: pointer;
}
.kyc__form > p {
  font-size: 14px;
  font-weight: 500;
}
.kyc__form > p > b {
  color: #046307;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  .kyc__body {
    min-height: 100vh;
    box-shadow: 0.5px 0.5px 5px -1px lightgray;
    width: 100%;
    margin: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 2.5%;
  }
  .kyc__body__section__one__select__div {
    width: 100%;
    border-bottom: 1px solid lightgray;
  }
  .apply__section__two__wrap {
    flex-direction: column;
  }
  .kyc__body__section__two {
    padding-top: 30px;
  }
  .kyc__form > button {
    width: 100%;
  }
}
