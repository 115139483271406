.signin__main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signin__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-width: 22.5%;
  padding: 40px 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.signin__form__logo {
  object-fit: contain;
  height: 50px;
  cursor: pointer;
}
.signin__form > input {
  padding: 10px 20px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}
.signin__form > input:focus {
  outline: #046307;
  border-color: #046307;
}
.signin__form > button {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  color: white;
  background-color: #046307;
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
}
.signin__form > p {
  font-size: 14px;
  font-weight: 500;
}
.signin__form > p > b {
  color: #046307;
  cursor: pointer;
}

@media all and (max-width: 768px) {
  .signin__form {
    gap: 20px;
    min-width: 90%;
    padding: 20px 20px;
  }
}
